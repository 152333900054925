import React, { useEffect, useState, useCallback } from 'react';
import {Layer} from "./config/interfaces"
import PointLayer from "./PointWFSLayer";
import PolygonLayer from "./PolygonWFSLayer";
import CartogramLayer from './CartogramWFSLayer';
import LineLayer from "./LineWFSLayer";
import PieLayer from "./PieWFSLayer";
import PieIconLayer from "./PieIconWFSLayer";
import SvgLayer from "./SvgWFSLayer";

interface WFSLayerProps {
  url: string;
  style?: React.ReactNode;
  layer: Layer;
  order: number;
}

const WFSLayer: React.FC<WFSLayerProps> = ({ url, layer, style, order }) => {

    switch (layer.geometry_type) {
        case "points":
          return (<PointLayer url={url} layer={layer} order={order} />)
        case "point":
          return (<PointLayer url={url} layer={layer} order={order} />)
        case "polygon":
          return (<PolygonLayer url={url} layer={layer} order={order} />)
        case "line":
          return (<LineLayer url={url} layer={layer} order={order}/>)
        case "cartogram":
          return (<CartogramLayer url={url} layer={layer} order={order}/>)
        case "pie":
          return (<PieLayer url={url} layer={layer} order={order}/>)
        case "diagramm":
          return (<PointLayer url={url} layer={layer} order={order} />)
        case "svg":
          return (<SvgLayer url={url} layer={layer} order={order} />)
        default:
          return null;
        }
};

export default WFSLayer;