import "ol/ol.css";
import { fromLonLat } from "ol/proj";
import { register } from "ol/proj/proj4.js";
import proj4 from "proj4";
import styles from "./Map.module.css";
import React, { useRef, useEffect, useState } from 'react';
import View from 'ol/View';
import * as olProj from 'ol/proj';

import { useDispatch, useSelector } from "react-redux";

import { selectMap } from "../../redux/map/selectors";
import { RControl, RMap, RLayerTileWebGL, RLayerWMS, RLayerTileWMS, RLayerTile } from "rlayers";
import ButtonScreenshot from "../ButtonScreenshot/ButtonScreenshot";
import { useConstructorMode } from "../../redux/constructorMode";

import MapLoader from "../Loaders/MapLoader/MapLoader";
import { deselect } from "../../redux/map/featureReducer";

import { InfoWindowOnClick } from "./InfoWindowOnClick";
import { setSelectedFeature, 
  setFeatureInfoWindow, 
  setInfoWindowFeatureOffset, 
  setZoom } from '../../redux/map/featureReducer';
import { getFeatureInfowindow, getFeatureMarker } from "./utils";
import { easeIn, easeOut } from 'ol/easing';
import WFSLayer from "./WFSLayer";
import { Layer } from "./config/interfaces";
import { generateSLD } from "./utils";

export default function Map() {
  const map_config = useSelector(selectMap).map_config;
  const [layers, setLayers] = useState<any[]>([]);

  proj4.defs(
    "EPSG:3576",
    "+proj=laea +lat_0=90 +lon_0=90 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs +type=crs"
  );
  register(proj4);

  const defaultProjection = 'EPSG:3857';

  const mapProjection = map_config?.projection || defaultProjection;

  const dispatch = useDispatch();

  const mapExtent = map_config?.extent
        ? [map_config.extent.xmin, map_config.extent.ymin, map_config.extent.xmax, map_config.extent.ymax]
        : null;

  const center = map_config?.center ? map_config.center : [30, 40];

  const initial = {
      center: fromLonLat(center),
      zoom: map_config?.zoom.start ? map_config.zoom.start : 3,
    };

    const mapRef = useRef<RMap>(null);

    const [prevProjection, setPrevProjection] = useState<string | null>(null);

    useEffect(() => {
        if (mapRef.current) {
            const map = mapRef.current.ol;

            // Если проекция изменилась
            if (prevProjection !== mapProjection) {
                const projection = olProj.get(mapProjection);
                
                if (projection) {
                    const newView = new View({
                        projection: projection,
                        center: olProj.fromLonLat(center, projection),
                        zoom: map_config?.zoom.start ? map_config.zoom.start : 3,
                    });

                    map.setView(newView);

                    // Обновляем extent, если оно задано
                    //if (mapProjection === 'EPSG:3857' && mapExtent) {
                        //newView.fit(mapExtent, { size: map.getSize() });
                    //}

                    setPrevProjection(mapProjection);
                }
            } else {
                // Если проекция не изменилась, обновляем только zoom и extent
                const view = map.getView();
                
                if (view && map_config) {
                      view.setZoom(map_config?.zoom.start);
                      setZoom(map_config?.zoom.start);
                      view.setCenter(fromLonLat(map_config?.center));
                    }
                    //if (mapProjection === 'EPSG:3857' && mapExtent) {
                        //view.fit(mapExtent, { size: map.getSize() });
                    //}
            }
      } 
    }, [map_config?.projection, map_config?.center, map_config?.zoom, prevProjection, mapProjection]);

    useEffect(() => {
      const map = mapRef.current?.ol;
  
      if (!map) return;
  
      const handleMapClick = (event: any) => {
        const feature = map.forEachFeatureAtPixel(event.pixel, (feat: any) => feat);

        if (feature) {

          const featureInfowindow = getFeatureInfowindow(feature, map_config?.layers);
          const {geometryType, featureMarker} = getFeatureMarker(feature, map_config?.layers);

          // fix it
          if (geometryType == "diagramm") {
            if (featureMarker?.size) {
              const featureProperties = feature.getProperties();
              const ioffset = Number(featureProperties[featureMarker?.size]) * (Number(featureMarker?.size_multiplication)) / 1.35;
              dispatch(setInfoWindowFeatureOffset(ioffset));
            } 
          }

          dispatch(setSelectedFeature(feature));
          dispatch(setFeatureInfoWindow(featureInfowindow));
        }
        else {
          dispatch(deselect());
        }
      };
  
      map.on('click', handleMapClick);
  
      return () => {
        map.un('click', handleMapClick);
      };
    }, [map_config?.layers, dispatch]);


    const handlePointerMove = (event: any) => {
      const map = mapRef.current?.ol;
  
      if (!map) return;
      const feature = map.forEachFeatureAtPixel(event.pixel, (feat: any) => feat);
      
      const mapTarget = map.getTarget();
      if (mapTarget instanceof HTMLElement) {
        mapTarget.style.cursor = feature ? 'pointer' : '';
      }
    };

    useEffect(() => {
      const map = mapRef.current?.ol;
  
      if (map) {
        map.on('pointermove', handlePointerMove);
      }

      return () => {
        if (map) {
          map.un('pointermove', handlePointerMove);
        }
      };
    }, []);

    const handleZoomChange = () => {
      const map = mapRef.current?.ol;
      if (!map) return;
  
      const zoom = map.getView().getZoom();
      if (zoom) {
        dispatch(setZoom(zoom));
      }
    };

    useEffect(() => {
      const map = mapRef.current?.ol;
      if (!map) return;
  
      const view = map.getView();
      view.on('change:resolution', handleZoomChange);
  
      return () => {
        view.un('change:resolution', handleZoomChange);
      };
    }, [dispatch]);

    const isConstructorMode = useSelector(useConstructorMode);

    const [isLoading, setIsLoading] = useState(true);

    const mapRefContainer = useRef<HTMLDivElement>(null);

    const zoom = useSelector((state: any) => state.selectFeature.currentZoom);
    console.log(zoom);

    const createLayer = (layerConfig: Layer) => {
      if (layerConfig.geometry_type === 'wms') {

        //const sldGenerated = generateSLD(layerConfig);
        
        return (
          <RLayerTileWMS
            key={layerConfig.layer}  // Используем уникальный id слоёв
            url={"https://geo.touristatlas.ru/geoserver/wms"}
            params={{
              LAYERS: `${layerConfig.workspace}:${layerConfig.layer}`,
              FORMAT: 'image/png',
              STYLES: layerConfig.marker?.sld ? layerConfig.marker?.sld : null,
              TILED: true,
            }}
          />
        );
      }
        return null;
      };

      useEffect(() => {
        if (map_config?.layers) {
          setLayers(map_config.layers.filter((layerConfig: any) => layerConfig.geometry_type === 'wms'));
        }
      }, [map_config?.layers]);


  return (
    <>
    {isConstructorMode && <ButtonScreenshot elementRef={mapRefContainer.current} />}
    <div className={styles.mapWrap} ref={mapRefContainer}>
      {isLoading && <MapLoader />}
      <RMap 
        ref={mapRef}
        width="100%" 
        height="100%" 
        projection={mapProjection}
        //extent={mapProjection === 'EPSG:3857' ? mapExtent : undefined}
        enableRotation={false}
        initial={initial}
        minZoom={map_config?.zoom.min ? map_config.zoom.min : 1}
        maxZoom={map_config?.zoom.max ? map_config.zoom.max : 24}
        noDefaultControls
        onRenderComplete={() => setIsLoading(false)}
      >
        <RControl.RZoom className={styles.zoom} />
        <InfoWindowOnClick />
        {mapProjection === 'EPSG:3857' ? (
          <>
          <RLayerTileWebGL
            properties={{ label: "Custom" }}
            url="https://tiles.touristatlas.ru/tile/{z}/{x}/{y}.png"
            attributions="Custom layers"
          />
          {layers.map((layerConfig: any) => createLayer(layerConfig))}

          {map_config?.layers.map((layerConfig: any, index: number) =>
            layerConfig.geometry_type !== 'wms' ? (
              <WFSLayer
                key={layerConfig.layer}
                url={'https://geo.touristatlas.ru/geoserver/'}
                layer={layerConfig}
                order={map_config?.layers.length - index}
              />
            ) : null
          )}
          <RLayerTileWMS
              url="https://geo.touristatlas.ru/geoserver/wms"
              params={{
                LAYERS: 'admin:russia_country_boundary_line',
                FORMAT: 'image/png',
                STYLES: 'country_boundary_line',
                TILED: true,
              }}
            />
          <RLayerTileWMS
              url="https://geo.touristatlas.ru/geoserver/wms"
              params={{
                LAYERS: 'admin:russia_subject_boundary_line_wo_coastline',
                FORMAT: 'image/png',
                STYLES: 'subject_boundary_line',
                TILED: true,
              }}
              visible={zoom > 5}
            />
          <RLayerTileWMS
              url="https://geo.touristatlas.ru/geoserver/wms"
              params={{
                LAYERS: 'admin:russia_rayons_boundary_line_wo_coastline',
                FORMAT: 'image/png',
                STYLES: 'subject_boundary_line',
                TILED: true,
              }}
              visible={zoom > 7}
            />
          </>
        ) : (
          <>
          <RLayerWMS
            url={map_config?.basemap ? map_config.basemap : ''}
            params={{
            bgcolor: "0xD3D5DA",
            TRANSPARENT: false,
            }}>
          </RLayerWMS>
          {layers.map((layerConfig: any) => createLayer(layerConfig))}

          {map_config?.layers.map((layerConfig: any, index: number) =>
            layerConfig.geometry_type !== 'wms' ? (
              <WFSLayer
                key={layerConfig.layer}
                url={'https://geo.touristatlas.ru/geoserver/'}
                layer={layerConfig}
                order={map_config?.layers.length - index}
              />
            ) : null
          )}
          </>
          )}
        </RMap>
    </div>
    </>
  );
  
}
