import React, { useCallback } from 'react';
import { RLayerVector, RFeature, ROverlay } from 'rlayers';
import useWFSLayer from './useWFSLayer';

import { Marker } from "./config/interfaces";
import Pie from "../Charts/PieChart/PieChart";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { getPieData } from "../Map/utils/get_pie_data";
import { FeatureCollection } from 'geojson';

interface PointLayerProps {
  url: string;
  layer: any;
  order: number;
}

const PieLayer: React.FC<PointLayerProps> = ({ url, layer, order }) => {
  const { layer_url, geojson } = useWFSLayer({ url, layer });

  const features = (geojson as FeatureCollection)?.features || [];

  const getMarker = (f: any, marker: Marker) => {
    const pieData = getPieData(f.properties, marker);
    
    return (
      <Pie
        values={pieData.sectors}
        radius={Number(pieData.size) * Number(pieData.size_multiplication)}
      />
    );
  };

  return (
    <RLayerVector 
      zIndex={order} 
      visible={true}>
        {features.map((f: any) =>
            layer.marker ? (
            <RFeature
                key={f.id}
                geometry={new Point(fromLonLat(f.geometry.coordinates))}
                properties={f.properties}
            >
                <ROverlay key={f.id} positioning="center-center">
                {getMarker(f, layer.marker)}
                </ROverlay>
            </RFeature>
            ) : null
        )}
    </RLayerVector>
  );
};

export default PieLayer;
